import React from "react";

export default function Footer() {
  return (
    <footer className="auth-footer">
      {" "}
      © 2020 Fancify <a href="#">Privacy</a> and{" "}
      <a href="#">Terms</a>
    </footer>
  );
}
