import {Spinner} from 'react-bootstrap';
import React from 'react';

export default function Logo({className, color, height}) {
  return (
      <svg className={className} style={{fill: color}} height={height} id="logo" data-name="logo"
           xmlns="http://www.w3.org/2000/svg"
           viewBox="0 0 378.28 306.96">
        <path
            d="M0,218.72c12.05-47.61,32.29-92.49,46.53-139.37a69.19,69.19,0,0,1,4.07-10C57.73,54.6,70,47.67,85.81,45.44c20.28-2.86,36.39,4.59,50,19,5,5.27,9.81,10.67,14.8,15.93a24.64,24.64,0,0,0,4.65,3.73,5.08,5.08,0,0,0,6.82-.72c12.82-12.49,41.24-12.43,53.94-.11,2.83,2.75,5.84,2.21,8.36-.1,3.94-3.62,7.46-7.71,11.32-11.43,6.18-6,12.06-12.39,18.84-17.6,19.77-15.19,50.87-9.94,63.62,2.77,6.27,6.24,10.37,13.7,13.25,21.9,12.8,36.44,25.73,72.84,38.18,109.41,4.09,12,8.43,24,8.7,37.1.8,39.2-28.44,74.59-67.13,80.45-44,6.66-83.75-21.62-91.89-65.32C211.88,201,237,161.09,276.29,149.82a8.91,8.91,0,0,0,3.79-1.79c-5.07,1.06-10.19,1.9-15.18,3.21-21.11,5.54-36,18.7-45.84,37.9-.86,1.69-.8,3.84-1.16,5.78-2.88,15.35-14.9,25-30.62,24.54-13.89-.4-25.35-11.61-27.35-26.61a17.22,17.22,0,0,0-1.8-5.58c-11.59-21.89-30.09-34-54.19-38.23a12.84,12.84,0,0,0-1.86-.15,12.69,12.69,0,0,0-1.74.3c2.77,1,5,1.68,7.15,2.49,34.5,12.8,55.2,45.92,52.17,83.49-2.71,33.74-30.06,63.5-64.61,70.32C50.52,314.28,6.7,282.79.73,237.71A16.06,16.06,0,0,0,0,235.22Zm298.1,58.92A50.85,50.85,0,0,0,298.62,176c-28.49-.09-51.15,22.3-51.21,50.59A50.46,50.46,0,0,0,298.1,277.64Zm-217.92,0a50.42,50.42,0,0,0,50.56-51.21c-.23-28.59-22.9-50.72-51.69-50.48-28,.24-50,23.23-49.85,52C29.39,255.39,52.42,277.85,80.18,277.64Z"/>
        <path
            d="M233,50.84c-5-22.7,9.87-44.05,33-49.62C287.92-4,313.44,7.59,320,38.84,288.63,25.22,259.48,27.46,233,50.84Z"/>
        <path
            d="M58.33,38.71c5-25.43,27.07-41.5,53.3-35.77,25.62,5.59,36.58,30.13,32.23,48.78C118.68,26.06,89.6,26.16,58.33,38.71Z"/>
      </svg>
  );
}
